import React, { useCallback, useMemo } from 'react';
import {
  ApolloClient,
  NetworkStatus,
  NormalizedCacheObject,
} from '@apollo/client';
import { ArrowRightIcon } from '@heroicons/react-v2/24/outline';
import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import {
  useMediaAnnouncementsQuery,
  BlockHeading,
  Typography,
} from '../../index';
import AnnouncementThumbnail from '../ui/announcement-thumbnail';
import Button from '../ui/button';

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

export interface InvestorPresentation {
  header: string;
  id: string;
  postedAt: string;
  socialVideoUrl?: string;
  subtypes?: (string | null)[];
  thumbnailUrl?: string;
  url: string;
  videoUrl?: string;
}

export interface InvestorPresentationsBlockProps {
  client?: ApolloClient<NormalizedCacheObject>;
  content: {
    heading?: string;
  };
  hub: string;
  isUK: boolean;
  listingKey: string;
  marketKey: string;
}

export const InvestorPresentationsBlock: React.ComponentType<
  InvestorPresentationsBlockProps
> = ({ client, content, hub, isUK, listingKey, marketKey }) => {
  const filterOptions = useMemo(() => {
    const items = [
      { key: 'market_key', value: marketKey },
      { key: 'ticker', value: listingKey },
    ];

    if (isUK) {
      items.push({
        key: 'subtypes',
        value: 'FR, IR, ACS, UPD',
      });
    } else {
      items.push({
        key: 'subtype',
        value: '03026',
      });
    }

    return items;
  }, [marketKey, isUK, listingKey]);

  const { data, fetchMore, loading, networkStatus } =
    useMediaAnnouncementsQuery({
      client,
      variables: {
        first: 6,
        hub,
        options: {
          filters: filterOptions,
          orders: [{ key: 'posted_at', value: 'desc' }],
        },
      },
    });

  const presentations = useMemo(() => {
    return data?.mediaAnnouncements?.edges
      ?.map((edge) => edge?.node)
      .filter((node): node is NonNullable<typeof node> => !!node)
      .map<InvestorPresentation>((node) => ({
        header: node.header,
        id: node.id,
        postedAt: node.postedAt,
        socialVideoUrl: node.socialVideoUrl || '',
        subtypes: node.subtypes || [],
        thumbnailUrl: node.thumbnailUrl || '',
        url: node.url,
        videoUrl: node.videoUrl || '',
      }));
  }, [data]);

  const hasNextPage = !!data?.mediaAnnouncements?.pageInfo.hasNextPage;

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.mediaAnnouncements?.pageInfo.endCursor,
        first: 3,
      },
    }).catch((e) => {
      Sentry.captureException(e);
    });
  }, [data, fetchMore]);

  const renderLoading = () => (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
      {[1, 2, 3].map((v) => (
        <div
          key={v}
          className="h-32 w-full animate-pulse rounded-lg bg-hubs-background-accent md:h-[272px]"
        />
      ))}
    </div>
  );

  return (
    <div
      className="mx-auto w-full max-w-7xl scroll-mt-40 px-4 md:px-6"
      id="investor_presentations_block"
    >
      {content.heading && (
        <BlockHeading withUnderline className="mb-8 pb-8">
          {content.heading}
        </BlockHeading>
      )}
      {loading ? (
        renderLoading()
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
          {!!presentations &&
            presentations.map((presentation) => (
              <Link
                key={presentation.id}
                className="group relative"
                href={`/announcements/${presentation.id}` || '#'}
              >
                <div className="flex flex-col gap-2">
                  <div className="block w-full overflow-hidden rounded-lg bg-hubs-background transition-shadow md:group-hover:shadow-lg">
                    <AnnouncementThumbnail
                      imageUrl={presentation.thumbnailUrl}
                      socialVideoUrl={presentation.socialVideoUrl}
                      subtypes={presentation.subtypes}
                      title={presentation.header}
                      videoUrl={presentation.videoUrl}
                    />
                  </div>
                  <Typography
                    className="font-body text-hubs-secondary opacity-100 transition-opacity group-hover:opacity-90"
                    variant="body-small"
                  >
                    <time dateTime={dayjs(presentation.postedAt).format()}>
                      {dayjs(presentation.postedAt).format('DD/MM/YYYY')}
                    </time>
                  </Typography>
                  <div className="flex w-full flex-row items-center justify-between gap-4 text-hubs-primary opacity-100 transition-opacity group-hover:opacity-90">
                    <Typography
                      className="line-clamp-2 font-body font-semibold"
                      variant="body-regular"
                    >
                      {presentation.header}
                    </Typography>
                    <ArrowRightIcon className="hidden size-5 min-w-5 transition-transform group-hover:translate-x-1/4 md:flex" />
                  </div>
                </div>
              </Link>
            ))}
        </div>
      )}
      {hasNextPage && (
        <div className="flex items-center justify-center pt-10">
          <Button
            className="w-full md:w-auto"
            disabled={isLoadingMore}
            label="Load more"
            onClick={onLoadMore}
          />
        </div>
      )}
    </div>
  );
};
