import React from 'react';
import { CodeBracketSquareIcon } from '@heroicons/react-v2/24/outline';
import { BlockHeading } from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';

export interface EmbedBlockProps {
  content: {
    embed?: string;
    heading?: string;
  };
}

export const EmbedBlock: React.ComponentType<EmbedBlockProps> = ({
  content,
}) => {
  return (
    <div className={BLOCK_WIDTH_CLASS}>
      {content.heading ? <BlockHeading>{content.heading}</BlockHeading> : null}
      {content.embed ? (
        <div dangerouslySetInnerHTML={{ __html: content.embed }} />
      ) : (
        <div className="flex aspect-video justify-center">
          <div className="flex w-full items-center justify-center gap-2 rounded-lg bg-gray-100 p-4 text-gray-500">
            <CodeBracketSquareIcon className="h-6 w-6" />
            <span className="text-sm">No content</span>
          </div>
        </div>
      )}
    </div>
  );
};
