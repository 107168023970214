import { ComponentType, SVGProps } from 'react';
import { LinkIcon, VideoCameraIcon } from '@heroicons/react-v2/24/outline';
import Image from 'next/legacy/image';
import {
  PdfIcon,
  MediaUpdateAttachmentType,
  MediaUpdateType,
} from '../../../index';
import { MediaUpdateInBlock } from './block';

interface Props {
  mediaUpdate: MediaUpdateInBlock;
}

const renderThumbnailOverlay = (
  Icon: ComponentType<SVGProps<SVGSVGElement>>,
  text?: string
) => (
  <span className="absolute inset-0 z-10 flex items-end overflow-hidden rounded-lg border bg-gradient-to-b from-gray-100/10 to-gray-100/25 p-4 text-hubs-secondary">
    <span className="flex w-full items-center gap-2">
      {<Icon className="h-6 w-6 shrink-0 text-hubs-secondary" />}
      {text ? (
        <span className="truncate">
          <span className="line-clamp-1 text-sm font-semibold">{text}</span>
        </span>
      ) : null}
    </span>
  </span>
);

export const UpdatesBlockMediaPreview: React.FC<Props> = ({ mediaUpdate }) => {
  switch (mediaUpdate.thumbnailAttachment?.type) {
    case MediaUpdateAttachmentType.Image:
      return mediaUpdate.thumbnailAttachment.url ? (
        <Image
          alt={mediaUpdate.thumbnailAttachment.title ?? ''}
          className="h-full rounded-lg children:rounded-lg"
          layout="fill"
          objectFit="cover"
          quality={100}
          src={mediaUpdate.thumbnailAttachment.url}
        />
      ) : null;
    case MediaUpdateAttachmentType.Video:
      return mediaUpdate.thumbnailAttachment.thumbnailUrl ? (
        <>
          <Image
            alt={mediaUpdate.thumbnailAttachment.title ?? ''}
            className="h-full rounded-lg children:rounded-lg"
            layout="fill"
            objectFit="cover"
            quality={100}
            src={mediaUpdate.thumbnailAttachment.thumbnailUrl}
          />
          {renderThumbnailOverlay(VideoCameraIcon)}
        </>
      ) : null;

    case MediaUpdateAttachmentType.Url:
      return mediaUpdate.thumbnailAttachment.url
        ? renderThumbnailOverlay(
            LinkIcon,
            mediaUpdate.thumbnailAttachment.title ||
              mediaUpdate.thumbnailAttachment.url
          )
        : null;

    case MediaUpdateAttachmentType.Pdf:
      return (
        <>
          {mediaUpdate.thumbnailAttachment.thumbnail ? (
            <Image
              alt={mediaUpdate.thumbnailAttachment.title ?? ''}
              className="h-full"
              layout="fill"
              objectFit="cover"
              quality={100}
              src={mediaUpdate.thumbnailAttachment.thumbnail}
            />
          ) : null}

          {renderThumbnailOverlay(PdfIcon)}
        </>
      );

    default:
      return mediaUpdate.includedTypes?.includes(MediaUpdateType.Video) &&
        !!mediaUpdate.attachments[0] ? (
        // temp until videos get a thumbnail
        <>
          <video
            className="h-full object-cover"
            src={mediaUpdate.attachments[0].url}
          />
          {renderThumbnailOverlay(VideoCameraIcon)}
        </>
      ) : null;
  }
};
