import { BLOCK_CLICKED_EVENT } from './constants';
// This is for when a user is in the hub builder and clicks on a block - it automatically loads the editor in the sidebar for that block
// Pairs with useBlockClickNavigation
export const emitBlockClickEvent = (blockId?: string) => {
  if (blockId) {
    // event must match the one in useBlockClickNavigation()
    const event = new CustomEvent(BLOCK_CLICKED_EVENT, { detail: blockId });
    window.dispatchEvent(event);
  }
};
