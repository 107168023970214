import React, { useCallback, useMemo } from 'react';
import {
  ApolloClient,
  NetworkStatus,
  NormalizedCacheObject,
} from '@apollo/client';
import { ArrowRightIcon } from '@heroicons/react-v2/24/solid';
import * as Sentry from '@sentry/nextjs';
import clsx from 'clsx';
import { format } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';
import {
  useMediaAnnouncementsQuery,
  BlockHeading,
  Typography,
} from '../../index';
import Button from '../ui/button';

export interface AnnualReport {
  header: string;
  id: string;
  postedAt: string;
  subtypes?: (string | null)[];
  thumbnailUrl?: string;
  url: string;
}

export interface AnnualReportsBlockProps {
  annualReports?: AnnualReport[];
  client?: ApolloClient<NormalizedCacheObject>;
  content: {
    heading?: string;
  };
  hasNextPage?: boolean;
  hub: string;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  isUK: boolean;
  listingKey: string;
  marketKey: string;
}

export const AnnualReportsBlock: React.ComponentType<
  AnnualReportsBlockProps
> = ({ client, content, hub, isUK, listingKey, marketKey }) => {
  const annualReportsSubtype = isUK ? 'ACS' : '03001';

  const { data, fetchMore, loading, networkStatus } =
    useMediaAnnouncementsQuery({
      client,
      variables: {
        first: 5,
        hub,
        options: {
          filters: [
            { key: 'market_key', value: marketKey },
            { key: 'subtype', value: annualReportsSubtype },
            { key: 'ticker', value: listingKey },
          ],
          orders: [{ key: 'posted_at', value: 'desc' }],
        },
      },
    });

  const annualReports = useMemo(() => {
    return data?.mediaAnnouncements?.edges
      ?.map((edge) => edge?.node)
      .filter((node): node is NonNullable<typeof node> => !!node)
      .map<AnnualReport>((node) => ({
        header: node.header,
        id: node.id,
        postedAt: node.postedAt,
        socialVideoUrl: node.socialVideoUrl || '',
        subtypes: node.subtypes || [],
        thumbnailUrl: node.thumbnailUrl || '',
        url: node.url,
        videoUrl: node.videoUrl || '',
      }));
  }, [data]);

  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.mediaAnnouncements?.pageInfo.endCursor,
        first: 8,
      },
    }).catch((e) => {
      Sentry.captureException(e);
    });
  }, [data, fetchMore]);

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const hasNextPage = !!data?.mediaAnnouncements?.pageInfo.hasNextPage;

  if (loading) {
    return (
      <div className="my-12 scroll-mt-40" id="annual_reports_block">
        <div className="mx-auto max-w-screen-xl scroll-mt-4 space-y-4 px-4 sm:px-6 lg:scroll-mt-[120px]">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4 md:gap-6 lg:gap-8 xl:gap-12">
            <div className="h-[380px] w-full animate-pulse bg-secondary-grey-light md:col-span-2 md:row-span-2 md:h-auto" />
            {[1, 2, 3, 4].map((v) => (
              <div
                key={v}
                className="h-[380px] w-full animate-pulse bg-secondary-grey-light"
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (!annualReports || annualReports.length === 0) {
    return null;
  }

  return (
    <div
      className="mx-auto w-full max-w-screen-xl scroll-mt-4 px-4 sm:px-6 lg:scroll-mt-[120px]"
      id="annual_reports_block"
    >
      {content.heading && (
        <BlockHeading withUnderline className="!pb-8">
          {content.heading}
        </BlockHeading>
      )}
      <div className="grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-4 md:gap-x-6 lg:gap-8 xl:gap-12">
        {annualReports.map((annualReport, index) => (
          <Link
            key={annualReport.id}
            className={clsx(
              'group relative col-span-1 row-span-1',
              index === 0 && 'md:col-span-2 md:row-span-2'
            )}
            href={`/announcements/${annualReport.id}` || '#'}
          >
            {/* Outer div just to add second drop shadow */}
            <div
              className="overflow-hidden rounded-lg md:h-full"
              style={{
                boxShadow: '0px 10px 15px -3px #0000001A',
              }}
            >
              <div
                className="block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 md:h-full"
                style={{
                  boxShadow: '0px 4px 6px -2px #0000000D',
                }}
              >
                <div className="relative z-10 w-full overflow-hidden rounded-lg pt-[132%] md:h-full md:pt-[138%]">
                  <div className="absolute inset-0 z-10 overflow-hidden rounded-lg bg-black opacity-5 transition-opacity md:group-hover:opacity-0" />
                  {annualReport.thumbnailUrl ? (
                    <Image
                      alt={annualReport.header}
                      className="absolute left-0 top-1/2 h-auto w-full -translate-y-1/2"
                      height={792}
                      src={annualReport.thumbnailUrl}
                      style={{ objectFit: 'contain' }}
                      width={560}
                    />
                  ) : null}
                  <div className="absolute inset-0 z-20 hidden items-end bg-gradient-to-b from-transparent to-black opacity-0 transition-opacity group-hover:opacity-100 md:flex">
                    <div
                      className={clsx(
                        'flex w-full items-center justify-between text-white',
                        index === 0 ? 'gap-8 p-8' : 'gap-4 p-4'
                      )}
                    >
                      <div className="truncate">
                        <p className="font-body text-xs font-medium">
                          <time
                            className="block text-sm leading-6"
                            dateTime={new Date(
                              annualReport.postedAt
                            ).toISOString()}
                          >
                            {format(
                              new Date(annualReport.postedAt),
                              'MMM d, yyyy'
                            )}
                          </time>
                        </p>
                        <p
                          className={clsx(
                            'line-clamp-1 font-heading text-lg font-semibold',
                            index === 0 ? 'md:text-2xl' : ''
                          )}
                        >
                          {annualReport.header}
                        </p>
                      </div>
                      <ArrowRightIcon
                        className={clsx(
                          'size-5 min-w-5',
                          index === 0 && 'md:size-6 md:min-w-6'
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:hidden">
              <p className="mt-2 font-body text-sm font-medium text-gray-500 group-hover:text-hubs-primary">
                <time
                  className="block text-xs leading-6 text-gray-600"
                  dateTime={new Date(annualReport.postedAt).toISOString()}
                >
                  {format(new Date(annualReport.postedAt), 'MMM d, yyyy')}
                </time>
              </p>
              <Typography
                className="line-clamp-2 font-semibold text-hubs-primary group-hover:text-hubs-secondary"
                variant="body-regular"
              >
                {annualReport.header}
              </Typography>
            </div>
          </Link>
        ))}
      </div>
      {hasNextPage && (
        <div className="flex items-center justify-center pt-10">
          <Button
            hasBorder
            className="w-full md:w-auto"
            disabled={isLoadingMore}
            label="Load more"
            onClick={onLoadMore}
          />
        </div>
      )}
    </div>
  );
};
