import React from 'react';
import clsx from 'clsx';
import { ImageMeta } from '../types';
import Button from '../ui/button';
import CloudinaryImage from '../ui/cloudinary-image';
import PlainText from '../ui/plain-text';
import { emitBlockClickEvent } from '../utils';

export interface HeroBlockProps {
  blockId: string;
  content: {
    backgroundImage?: ImageMeta | null;
    buttons?: HeroButton[];
    description?: string;
    heading?: string;
    variant?: 'angled_image' | 'straight_image';
  };
  index?: number;
  removeBottomSpacing?: boolean;
  removeTopSpacing?: boolean;
  renderButtons: (buttons: HeroButton[]) => React.ReactNode;
}

interface HeroButton {
  hasArrow: boolean;
  isPrimary: boolean;
  label: string;
  openInNewTab: boolean;
  url: string;
}

const PLACEHOLDER_HEADING = 'Data to enrich your online business';
const PLACEHOLDER_DESCRIPTION =
  'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.';

export const AngledHeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderButtons,
}) => {
  return (
    <div
      className={clsx(
        `relative scroll-mt-[120px]`,
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      id="hero_block"
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div className="mx-auto grid w-full max-w-screen-xl overflow-hidden lg:grid-cols-12 lg:gap-x-16">
        <div className="relative z-10 flex items-center lg:col-span-8 lg:w-full lg:max-w-2xl xl:col-span-7">
          {/* h-[101%] below & overflow-hidden in parent to cover a little visual bug on some browsers where the svg doesnt fully cover image */}
          <svg
            aria-hidden="true"
            className="absolute inset-y-0 right-0 hidden h-[101%] w-80 translate-x-1/2 fill-hubs-background lg:block"
            preserveAspectRatio="none"
            viewBox="0 0 100 100"
          >
            <polygon points="0,0 90,0 50,100 0,100" />
          </svg>
          <div className="z-10 flex max-w-2xl flex-col items-start gap-8 px-4 py-24 sm:px-6 lg:mx-0 lg:min-h-[640px] lg:justify-center lg:pr-0 2xl:min-h-[720px]">
            <h1 className="font-heading text-4xl font-semibold text-hubs-primary md:text-5xl">
              {content.heading ?? PLACEHOLDER_HEADING}
            </h1>
            <PlainText
              className="font-body text-lg leading-normal text-hubs-secondary"
              content={content.description ?? PLACEHOLDER_DESCRIPTION}
            />
            {renderButtons(content.buttons ? content.buttons : [])}
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-hubs-background-accent lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:py-0">
        {content.backgroundImage && (
          <CloudinaryImage
            alt=""
            className="aspect-video h-full w-full object-cover lg:aspect-auto lg:object-left"
            crop="fill"
            gravity="auto"
            height={1000}
            priority={index === 0}
            src={content.backgroundImage.cloudinaryUrl || ''}
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

export const StraightHeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderButtons,
}) => {
  return (
    <div
      className={clsx(
        `relative`,
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div className="relative z-[1] mx-auto grid w-full max-w-screen-xl px-4 md:px-6 lg:grid-cols-12 lg:gap-x-16">
        <div className="flex max-w-2xl flex-col gap-8 bg-hubs-background py-24 sm:pr-6 lg:col-span-7 lg:mx-0 lg:min-h-[640px] lg:justify-center lg:px-0 xl:col-span-6 2xl:min-h-[720px]">
          <h1 className="font-heading text-4xl font-semibold text-hubs-primary sm:mt-10 md:text-5xl lg:mt-0">
            {content.heading ?? PLACEHOLDER_HEADING}
          </h1>
          <PlainText
            className="font-body text-lg leading-normal text-hubs-primary"
            content={content.description ?? PLACEHOLDER_DESCRIPTION}
          />
          {renderButtons(content.buttons ? content.buttons : [])}
        </div>
      </div>
      <div className="relative z-0 bg-hubs-background-accent lg:absolute lg:inset-0 lg:left-1/2 lg:-ml-8 lg:bg-transparent lg:py-0 xl:ml-0">
        {content.backgroundImage && content.backgroundImage.cloudinaryUrl && (
          <CloudinaryImage
            alt=""
            className="aspect-video h-full w-full object-cover lg:absolute lg:right-0 lg:aspect-auto lg:h-full lg:w-[90%] lg:object-cover"
            crop="fill"
            gravity="auto"
            height={1000}
            priority={index === 0 ? true : false}
            src={content.backgroundImage.cloudinaryUrl || ''}
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

export const HeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
}) => {
  function renderButtons(buttons: HeroButton[]) {
    // this hides button parent div if no buttons, or if both buttons have empty labels
    // the flex parent of this div creates extra gap/space without this handler
    // inside HeroBlock wrapper for DRY reasons
    if (!buttons.length || buttons.every((button) => button.label === ''))
      return null;
    return (
      <div className="z-0 flex w-full flex-col items-center gap-6 md:w-auto md:flex-row">
        {buttons.map((button, index) => {
          if (!button.label) return null;
          return (
            <Button
              key={index}
              className="w-full justify-center md:w-auto"
              hasArrow={button.hasArrow}
              isPrimary={button.isPrimary}
              label={button.label}
              openInNewTab={button.openInNewTab}
              size="lg"
              url={button.url}
            />
          );
        })}
      </div>
    );
  }
  if (content.variant === 'angled_image') {
    return (
      <AngledHeroBlock
        blockId={blockId}
        content={content}
        index={index}
        removeBottomSpacing={removeBottomSpacing}
        removeTopSpacing={removeTopSpacing}
        renderButtons={renderButtons}
      />
    );
  }

  return (
    <StraightHeroBlock
      blockId={blockId}
      content={content}
      index={index}
      removeBottomSpacing={removeBottomSpacing}
      removeTopSpacing={removeTopSpacing}
      renderButtons={renderButtons}
    />
  );
};
