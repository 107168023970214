import React from 'react';
import clsx from 'clsx';
import Typography from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { ImageMeta } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';
import { emitBlockClickEvent } from '../utils';

export interface HeaderBlockProps {
  blockId?: string; // Add this line
  content: {
    background?: 'none' | 'image' | 'primaryThemeColor' | 'accentThemeColor';
    backgroundImage?: ImageMeta | null;
    darkenBackgroundImage?: boolean;
    description?: string;
    heading?: string;
    variant?: 'left_aligned' | 'center_aligned';
  };
  index?: number;
  nextBlockType?: string | undefined | null;
  removeBottomSpacing?: boolean;
  removeTopSpacing?: boolean;
}

const PLACEHOLDER_HEADING = 'Please add a heading';

export const HeaderBlock: React.ComponentType<HeaderBlockProps> = ({
  blockId,
  content,
  index,
  nextBlockType,
  removeBottomSpacing,
  removeTopSpacing,
}) => {
  const descriptionHTML = content.description
    ? content.description.replace(/\n/g, '<br />')
    : null;

  const getBgColorClass = () => {
    switch (content.background) {
      case 'primaryThemeColor':
        return 'bg-company-primary';
      case 'accentThemeColor':
        return 'bg-company-accent';
      default:
        return 'bg-hubs-background';
    }
  };

  const getTextColorClass = () => {
    switch (content.background) {
      case 'primaryThemeColor':
        return 'text-company-primary-text';
      case 'accentThemeColor':
        return 'text-company-accent-text';
      default:
        return 'text-hubs-primary';
    }
  };
  // Can't just use none, as many blocks have no background set
  const isBgNoneOrUndefined = (content.background === 'none' ||
    !content.background) as boolean;
  return (
    <div
      className={clsx(
        'relative scroll-mt-[120px] py-16 sm:py-20',
        (removeTopSpacing || isBgNoneOrUndefined) && '-mt-16 md:-mt-20',
        // AS: Removes bottom spacing if a.) the next block is not a hero block
        // and b.) either removeBottomSpacing === true or the background is 'none'
        nextBlockType !== 'hero' &&
          (removeBottomSpacing || content.background === 'none') &&
          '-mb-16 md:-mb-20',
        content.background === 'image'
          ? content.backgroundImage
            ? ''
            : 'bg-gray-900' // give a dark bg when no image is set, as the overlaid text is white
          : getBgColorClass()
      )}
      id="header_block"
      onClick={() => emitBlockClickEvent(blockId)}
    >
      {content.background === 'image' && content.backgroundImage && (
        <div className="absolute inset-0 h-full w-full">
          {content.darkenBackgroundImage === true && (
            <div className="absolute inset-0 z-[2] bg-black/40" />
          )}
          <CloudinaryImage
            alt={content.heading ?? ''}
            className="absolute inset-0 z-[1] h-full w-full object-cover"
            fill={true}
            priority={index === 0}
            sizes="100vw"
            src={content.backgroundImage.cloudinaryUrl || ''}
          />
        </div>
      )}
      <div
        className={`${BLOCK_WIDTH_CLASS} relative z-[3] mx-auto max-w-screen-xl px-4 md:px-6`}
      >
        <div
          className={clsx(
            'mx-auto flex max-w-4xl flex-col gap-6',
            content.variant === 'center_aligned' ? 'text-center' : 'lg:mx-0'
          )}
        >
          <Typography
            className={clsx(
              'font-heading text-3xl font-semibold md:text-5xl',
              content.variant === 'center_aligned'
                ? 'text-center'
                : 'text-left',
              content.background === 'image'
                ? 'text-white'
                : getTextColorClass()
            )}
            variant="heading-2"
          >
            {content.heading ?? PLACEHOLDER_HEADING}
          </Typography>
          {descriptionHTML && (
            <Typography
              className={clsx(
                'font-body text-lg leading-normal',
                content.background === 'image'
                  ? 'text-white'
                  : content.background === 'none'
                  ? 'text-hubs-secondary'
                  : getTextColorClass(),
                content.variant === 'center_aligned'
                  ? 'text-center'
                  : 'text-left'
              )}
              component="div"
              variant="paragraph"
            >
              <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
